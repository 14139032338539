/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* I want to use this line to set tailwind layer defined below to have a lower priority than Mantine, but it doesn't seem to work. don't know why*/
/* @layer tailwind, mantine;

@layer tailwind {
  @tailwind base;
} */

@layer {
  @tailwind base;
}

/* @import "@mantine/core/styles.layer.css";
@import "@mantine/core/styles.css";
@import "@mantine/dates/styles.css"; */

/* color: var(--mantine-color-red-5);
  background: var(--mantine-color-grape-9);
  border: rem(1px) solid var(--mantine-color-blue-1); */
@layer base {
  :root {
    --mantine-font-family: 'muli', sans-serif !important;
    --mantine-font-family: 'muli', sans-serif !important;
    --mantine-font-family-headings: 'muli, sans-serif' !important;
    font-family: var(--mantine-font-family) !important;
    /* font-size: ; */

    --mantine-color-body: #f0f0f0 !important;
    --mantine-color-primary: #317973 !important;
    --mantine-color-secondary: #6092c0 !important;
    --mantine-primary-color-filled: #ff734d !important;
    /* --stepper-color: #317973 !important; */
    --button-bg: #317973 !important;
    --button-bd: #317973 !important;
    --button-color: #317973 !important;
    /* var(--mantine-color-primary); */
    /* 37847c */
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 1rem;
  }

  .dark {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 1rem;
    /* --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%; */
  }
}

/* @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: var(--mantine-font-family);
  font-size: var(--mantine-font-size-md);
  line-height: var(--mantine-line-height);
  background-color: var(--mantine-color-body);
  color: var(--mantine-color-text);

  -webkit-font-smoothing: var(--mantine-webkit-font-smoothing);
  -moz-osx-font-smoothing: var(--mantine-moz-font-smoothing);
} */
